import { useEffect,useState } from 'react'
import { useSearchParams,useNavigate } from "react-router-dom";
import { authClient,acceptTerms } from "../api/apiCalls";
import { useStateValue } from "../state/context";
import '../styles/dashboard.scss'
import Footer from "../components/basics/Footer" 

export default function Dashboard(props){
    const navigate = useNavigate();
    const [{user},dispatch] = useStateValue();
    const [searchParams] = useSearchParams();
    const [token] = useState(searchParams.get("token") ? searchParams.get("token") : window.localStorage.getItem("token")) 
    useEffect(()=>{
        authHandler()        
   },[props])

    const authHandler  = async ()=>{
        if(token){
            authClient(token).then(res=>{
                if(res.result.get("Terms")){
                    console.log("🚀 ~ file: Dashboard.jsx ~ line 21 ~ authClient ~ res.result", res.result.get("Terms"))                    
                    navigate("/services")
                }else{
                    console.log("🚀 ~ file: Dashboard.jsx ~ line 21 ~ authClient ~ res.result", res.result.get("Terms"))
                    // navigate("/error")
                }
            })
        }else{
            navigate("/error")
        }
    }

    const handleToS = ()=>{
        acceptTerms(user.id).then(res=>{
            if(res.result){
                
               
            }else{
                navigate("/error")
            }
        })
    }

   
    return(
        <div className=" mainContainer">
           <h1>Terminos y condiciones del servicio</h1>
           <h4>Terminos y servicios</h4>
           <button onClick={handleToS}>Aceptar terminos</button>
            <Footer  color="white"/>
        </div>
    )

}