import '../../styles/error.scss'
import isotipoAmarillo from "../../assets/isotipoAmarillo.png"
import isotipoNegro from "../../assets/isotipoNegro.png"

export default function Footer(props){  
    return(
        <div className={"footer " + props.className}>
            <img src={props.color === "black" ? isotipoAmarillo:isotipoNegro} alt="isotipo" className="poweredByImage"  />
            <span className="poweredByText" >Wolly, proveedor oficial de Mutua Madrileña, Línea Directa, AXA, Pelayo y muchos mas.</span>
        </div>
    )
}