import { useState } from "react"
import star from"../../assets/star.svg"
import starFill from"../../assets/star-fill.svg"

import  "../../styles/review.scss"

export default function ReviewStars(props){
    const [points,setPoints] = useState(0)
    const handleSetPoints= (points)=>{
        setPoints(points)
        props.handleReviewPoints(points)
    }
    return(
       <div className="review">
            <button
                onClick={() => handleSetPoints(1)}
            >
                <img src={points >= 1 ? starFill : star} alt="star-fill"/>
            </button>
            <button
                onClick={() => handleSetPoints(2)}
            >
                <img src={points >= 2 ? starFill : star} alt="star-fill2"/>
            </button>
            <button
                onClick={() => handleSetPoints(3)}
            >
                <img src={points >= 3 ? starFill : star} alt="star-fill3"/>
            </button>
            <button
                onClick={() => handleSetPoints(4)}
            >
                <img src={points >= 4 ? starFill : star} alt="star-fill4"/>
            </button>
            <button
                onClick={() => handleSetPoints(5)}
            >
                <img src={points >= 5 ? starFill : star} alt="star-fill5"/>
            </button>
        </div>
    )

}