import { fetchFromAPI } from "../config/api";

// PAYMENTS


export const createPaymentLinkLead = async (jobId,wollerId,price,review) => {
  console.log(jobId,wollerId,price)
    const res = await fetchFromAPI("createPaymentLinkLead", {jobId:jobId,wollerId:wollerId,price:price,review:review});
    return res;
}


// JOBS 

export const getJobsFromUser = async (userId) => {
    const res = await fetchFromAPI("getJobsFromUser", {id: userId});
    return res;
}
export const getJobById = async (jobId) => {
    const res = await fetchFromAPI("getJobByIdWollyPay", {id: jobId});
    return res;
}

//User
export const authUser = async (token) =>{
    try {
      return {
        status: "ok",
        result: await await fetchFromAPI("authUser", {token:token})
      };
    } catch (error) {
      return {
        status: "error",
        result: error
      };
    }
}
export const authClient = async (token) =>{
    try {
      return {
        status: "ok",
        result: await await fetchFromAPI("authClient", {token:token})
      };
    } catch (error) {
      return {
        status: "error",
        result: error
      };
    }
}

export const acceptTerms = async () =>{
    try {
      return {
        status: "ok",
        result: await await fetchFromAPI("acceptTerms")
      };
    } catch (error) {
      return {
        status: "error",
        result: error
      };
    }
}

//utils
export const generateLink = async (customerId) =>{
    try {
      return {
        status: "ok",
        result: await fetchFromAPI("generateStripeLink",{customerId:customerId})
      };
    } catch (error) {
      return {
        status: "error",
        result: error
      };
    }
}