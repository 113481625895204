import { useState } from 'react';
import BaseModal from "../basics/BaseModal.jsx"
import ReviewStars from "../basics/ReviewStars.jsx"
import CurrencyInput from 'react-currency-input-field'; 

import Lottie from 'react-lottie'
import * as cardSpinnerData from "../../assets/animations/button-loading.json";

import "../../styles/priceModal.scss"

import stripeImage from"../../assets/stripe.png"
import guaranteeImage from"../../assets/guarantee.png"

export default function PriceModal(props){
    const [priceToPay ,setPriceToPay] = useState()
    const [isLoading ,setIsLoading] = useState(false)
    const [review, setReview]= useState(0)

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: cardSpinnerData.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
   
    const sendPrice= ()=>{
        props.handlePrice(priceToPay,review)
    }
    return(
        <BaseModal className={"priceModal"} closeModal={props.closeModal} title={"¿Cuánto vas a pagar al profesional?"}>
                <div className='currencyContainer'>
                    <label className='labelCurrency'>
                        <CurrencyInput
                            id="input-example"
                            name="input-name"
                            placeholder="0"
                            defaultValue={0}
                            intlConfig={null}
                            decimalsLimit={2}
                            onValueChange={(ammount)=>{setPriceToPay(parseFloat(ammount) +2.95)}}
                            className="currencyInput"
                        />
                    </label>
                    <button className='button buttonBlue' onClick={()=>{setIsLoading(true);sendPrice()}} disabled={(!priceToPay || priceToPay===0)}>
                        { isLoading ?( <Lottie style={{height: 27, width: 77}}options={defaultOptions} />):
                            priceToPay ?  `Pagar ${priceToPay}€`:
                                "0€"}</button>
                    <p>El pago incluye el seguro</p>
                    <div className='reviewContainer'>
                        <span>Valora el servicio del Woller</span>
                        <ReviewStars handleReviewPoints={setReview}/>
                    </div>
                </div>
                
                <div className='guarranteeContainer'>
                    <p>Tu pago está protegido con la política de protección de Wolly gracias al seguro incluido en el pago.</p>
                    <div className="imagesContainer">
                        <img alt="stripe"  className='stripeImage' src={stripeImage}></img>
                        <img alt="guarantee"  className='guaranteeImage' src={guaranteeImage}></img>
                    </div>
                </div>
                <div className='buttonMobile'>
                    <button className='button buttonBlue' onClick={()=>{setIsLoading(true);sendPrice()}} disabled={!priceToPay || priceToPay<1?true:false}>
                        { isLoading ?( <Lottie style={{height: 27, width: 77}}options={defaultOptions} />):
                            priceToPay ?  `Pagar ${priceToPay}€`:
                                "0€"}</button>
                    <p>El pago incluye el seguro</p>
                </div>
        </BaseModal>
    )

}