
export default function BaseModal(props){
    return(
        <div className='modalBackground'>
            <div  className={'modalContainer '+props.className} >
                <div onClick={props.closeModal} className="close"><i class="fa-solid fa-xmark"></i></div>
                <div className="modalContent">
                    <h1 className="modalTitle">{props.title}</h1>
                    {props.children}
                </div>
            </div>
        </div>
    )

}