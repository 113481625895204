
import { useState,useEffect } from "react";
import { useStateValue } from "../../state/context";
import { useParams  } from "react-router-dom";
import { getJobById,createPaymentLinkLead } from "../../api/apiCalls"
import PriceModal from "../../components/modals/PriceModal";
import Footer from "../../components/basics/Footer";
import calendar from "../../assets/calendarIcon.svg"
import direcion from "../../assets/houseIcon.svg"
import defaultWollerIcon from "../../assets/defaultWollerIcon.png"
import '../../styles/detailsPage.scss'


export default function DetailsPage(){
    const [{job},dispatch] = useStateValue();
    const [showPriceModal, setShowPriceModal] = useState(false)
    const [selectedWoller,setSelectedWoller] = useState(null)
    const navParams = useParams ();
    useEffect(()=>{
        if(!job.id){
            getJobById(navParams.jobId).then(resJob => {
                 if(resJob){
                    dispatch({
                        type: "SET_JOB_DATA",
                        id:resJob.id,
                        Address:resJob.Address,
                        Country:resJob.Country,
                        CreatedAt:resJob.createdAt,
                        Description:resJob.Description,
                        Location:resJob.Location,
                        Subvertical:resJob.Subvertical,
                        Title:resJob.Title,
                        Type:resJob.Type,
                        verticalIcon:resJob.verticalIcon,
                        nJober:resJob.nJober,
                        wollers:resJob.wollers
                    });
                }
            	
            })
        }
    },[job])

    const getWollerServicePrice= async (ammount,review)=>{
       
       let result= await createPaymentLinkLead(job.id,selectedWoller.id,ammount,review)
       if(result){
        window.location.assign(result)
       }
    }
    
        return(
            <div className="details mainContainer">
                {showPriceModal && <PriceModal closeModal={()=>setShowPriceModal(false)} handlePrice={getWollerServicePrice} />}
               
                    <h1 className="title">Estos son los datos de tu servicio</h1>
                    <p className="subTitle">Revisa la información que nos proporcionaste</p>
                <div className="infoContainer">
                        <div className="jobDetailsContainer card">
                            <div className="guildContainer">
                                <img src={job.verticalIcon} alt=""  />
                                <p>{job.Type} </p>
                            </div>
                            <h1>{job.Title}</h1>
                            <div className="jobDetailsDescriptionContainer" >
                                <img src={calendar} alt="calendar" width="20"height="20" />
                                {job.CreatedAt && <p>{job.CreatedAt.toUTCString()}</p>}
                            </div>
                            <div className="jobDetailsDescriptionContainer" >
                                <img src={direcion} alt="direccion"  />
                                <p>{job.Address}, {job.Country} </p>
                            </div>
                            
                           
                            <div className="yellowLabel">
                                <p>{job.Type} {job.Subvertical ? ` → ${job.Subvertical.type}`:""}</p>

                            </div>

                            
                        </div>
                    <div className="wollersContainer ">
                        <h3 className="title">¿Quién realizó el servicio?</h3>
                        <p className="subTitle">Selecciona al profesional que atendió tu servicio</p>
                        {job.wollers && job.wollers.map(woller=>{
                            return(<div className="wollerContainer card" key={woller.id} onClick={()=>{setShowPriceModal(true);setSelectedWoller(woller)}}>
                                <div className="wollerDataContainer">
                                    <img src={woller.userPic ? woller.userPic:defaultWollerIcon} alt="wollerProfile" />
                                    <p>{woller.Name}</p>
                                   
                                </div>
                                <p className="finalArrow"><b>{">"}</b></p>

                            </div>)
                        })}
                    </div>
                </div>
               <Footer color="white"/>
            </div>
        )
    

}