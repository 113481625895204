
import '../../styles/generateLink.scss'
import payLogo from "../../assets/wollyPayLogo.png"
import Footer from "../../components/basics/Footer"
import ButtonSpinner from "../../components/basics/ButtonSpinner"
import { authClient, generateLink  } from "../../api/apiCalls";
import { useSearchParams,useNavigate } from "react-router-dom";
import { useEffect ,useState} from 'react'

export default function GenerateLink(){
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [customerId] = useState(searchParams.get("customerId"))

    useEffect(()=>{
        authHandler()        
   },[])

    const authHandler  = async ()=>{
        if(customerId){
            generateLink(customerId).then(async (res)=>{
                let redirectTo = res.result
                if (redirectTo.includes("https://"))
                    window.location.replace(redirectTo)
                navigate("/error");
            }).catch(() => {
                navigate("/error");
            })
        }else{
            navigate("/error")
        }
    }

 
      
    return(
        <div className="generateLink mainContainer">
            <div className="titleContainer">
                <img src={payLogo} alt="WollyPay Logo"  />
                <h1 className="title">Generando enlace seguro</h1>
            </div>
            <div className="stepsContainer">
                <p className="step">Al generarse el enlace seras redirigido a la recoleccion segura de tus datos de pago.</p>
            </div>
            <div className="spinner">
                <ButtonSpinner/>
            </div>
            <Footer color={"black"}/>
        </div>
    )

}