
import '../../styles/finishPage.scss'
import serviceOwned from "../../assets/serviceOwnedIcon.png"
import starIcon from "../../assets/starIcon.png"

export default function FinishPage(){
   
    return(
        <div className="finish mainContainer">
            <div className="titleContainer">
                <img src={serviceOwned} alt="finish"  />
                <h1 className="title">¡Servicio pagado correctamente!</h1>
            </div>
            <div className='infoContainer'>
                <p>Muchas gracias por confiar en Wolly y en nuestra red de Profesionales.</p>
                <p>El profesional recibirá el pago en 15 días, salvo que por alguna incidencia nos indiques que debemos retener el pago hasta la resolución de la misma.</p>
                <h2><img src={starIcon} alt="finish"  /> <b>Tu opinion es muy importante</b></h2>
                <p>Te agradeceríamos mucho si pudieras dejar una reseña en Google para ayudar a otros clientes a encontar al profesional que necesitan.</p>
            </div>
            
        </div>
    )
    

}