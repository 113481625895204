import { useEffect, useState } from "react";
import { authUser,getJobById } from "../../api/apiCalls";
import { useSearchParams,useNavigate } from "react-router-dom";
import { useStateValue } from "../../state/context";
import '../../styles/wollyPay.scss'
import payLogo from "../../assets/wollyPayLogo.png"
import Footer from "../../components/basics/Footer" 

export default function WollyPayPage(props){
    let navigate = useNavigate();
    const [{job},dispatch] = useStateValue();
    const [searchParams] = useSearchParams();
    const [token] = useState(searchParams.get("token") ? searchParams.get("token") : window.localStorage.getItem("token")) 
    const [jobId] = useState(searchParams.get("id") ? searchParams.get("id") : null)
    const [loadReady,setLoadReady] = useState(false)
    const [navigateTo, setNavigateTo] = useState()
    const [userAgent, setUserAgent] = useState("Android")

  
    useEffect(()=>{
         authHandler()
         setUserAgent(getMobileOperatingSystem())
         
    },[props])


    const getMobileOperatingSystem = ()=> {
        let userAgent_ = navigator.userAgent || navigator.vendor || window.opera;
    
       
    
        if (/android/i.test(userAgent_)) {
            return "Android";
        }else if (/iPad|iPhone|iPod/.test(userAgent_) && !window.MSStream) {
            return "ios";
        }
    
        return "Android";
    }
   
    const authHandler  = async ()=>{
        if(token && jobId){
            authUser(token).then(res=>{
                if(res.result){
                    window.localStorage.setItem("token",token)
                    dispatch({
                        type: "SET_USER_DATA",
                        id:res.result.id,
                        business:res.result.get("Business")?.id,
                        name:res.result.get("Name"),
                        phone:res.result.get("Phone"),
                        location:res.result.get("Location"),
                        email:res.result.get("email"),
                        userPic:res.result.get("UserPic")?.url(),
                    });
                    getJobById(jobId).then(resJob => {
                         if(resJob){
                            	if(resJob.State === "abierto" ){
                                	setNavigateTo(`/services/${resJob.id}`)
                            	}else if(resJob.State === "full"){
                                	setNavigateTo(`/services/${resJob.id}`)
                            	}else if(resJob.State === "finalizado" || resJob.State === "review" ){
                                	setNavigateTo(`/finish`)
                            	}
                           	setLoadReady(true)
                        	}else{
                            	navigate("/error")
                        	}
                    	
                    })
                }else{
                    navigate("/error")
                }
            })
        }else{
            navigate("/error")
        }
    }
    return(
        <div className={userAgent==="iOS" ? "wollyPay mainContainer ios": "wollyPay mainContainer"}>
            <div className="titleContainer">
                <img src={payLogo} alt="WollyPay Logo"  />
                <h1 className="title">Pago seguro con Wolly Pay</h1>
                <p className="subTitle">Con Wolly Pay pagas al profesional con rapidez, de forma cómoda y segura. Y además, disfrutas de estas importantes ventajas:</p>
            </div>
            <div className="stepsContainer">
                <p className="step step1">Retenemos tu pago durante 15 días para garantizar el cumplimiento del servicio.</p>
                <p className="step step2">Te beneficias de una cobertura adicional de 300.000 euros con nuestra póliza de Responsabilidad Civil y seis meses de garantía.</p>
                <p className="step step3">Conservas tu recibo y tu factura.</p>
                <p className="step step4">Tienes varias opciones de pago desde una única plataforma. Mira además las condiciones para obtener financiación.</p>
            </div>
            <button className="button buttonYellow" disabled={!loadReady} onClick={()=>{navigate(navigateTo)}}>Continuar</button>
            <Footer className="dashboardFooter" color="black"/>
        </div>
    )

}