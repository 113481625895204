import Lottie from 'react-lottie'
import * as cardSpinnerData from "../../assets/animations/button-loading.json";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: cardSpinnerData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
}

export default function ButtonSpinner(){
    return(
        <>
            <Lottie style={{height: 30, width: 80}}options={defaultOptions} />
        </>
    )
}
