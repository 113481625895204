// import { useEffect, useState } from "react";
// import { getJobsFromUser } from "../../api/apiCalls";
// import { useStateValue } from "../../state/context";
// import { useNavigate } from "react-router-dom";

export default function ServicesPage(){
    // let navigate = useNavigate();
    // const [{user}] = useStateValue();
    // const [services,setServices] = useState() 
  
    // // useEffect(()=>{
    // //     if(user.id !=null){
    // //         loadServices()
    // //     }else{
    // //         navigate("/")
    // //     }
    // // },[user])
    
   
    // const loadServices = async ()=>{
       
    //     getJobsFromUser(user.id).then(res=>{
    //         console.log(res)
    //         if(res){
    //             setServices(res.result.token)
    //         }
    //     })
    // }
    // return(
    //     <div><h1>servicios</h1>{services}</div>
    // )

}