
export const reducer = (state, action) => {
    switch (action.type) {
        case "SET_USER_DATA":
            return {
                ...state,
                user:{
                    id:action.id,
                    business:action.business,
                    name:action.name,
                    phone:action.phone,
                    location:action.location,
                    email:action.email,
                    userPic:action.userPic,
                }
            };
        case "SET_JOB_DATA":
            return {
                ...state,
                job:{
                    id:action.id,
                    Address:action.Address,
                    Country:action.Country,
                    Description:action.Description,
                    CreatedAt:action.CreatedAt,
                    Location:action.Location,
                    Subvertical:action.Subvertical,
                    Title:action.Title,
                    Type:action.Type,
                    verticalIcon:action.verticalIcon,
                    nJober:action.nJober,
                    wollers:action.wollers,
                }
            };
        default:
            return state;
    }
};
