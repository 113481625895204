
import React from "react";
import { Routes, Route,HashRouter } from "react-router-dom";
import { alteredState } from "./state/alteredState";
import { reducer } from "./state/reducer";
import { StateProvider } from "./state/context";

import Dashboard from "./pages/Dashboard"
import WollyPay from "./pages/wollyPay/wollyPayPage"
import Error from "./pages/error/ErrorPage"
import Services from "./pages/services/ServicesPage"
import Details from "./pages/details/DetailsPage"
import Finish from "./pages/finish/FinishPage"
import GenerateLink from "./pages/utils/GenerateLink"


const App = () => {

    return (
      <HashRouter>
        <StateProvider initialState={alteredState} reducer={reducer}>
            <Routes>
                <Route index  path="/" element={<Dashboard/>} />
                <Route path="wollyPay" element={<WollyPay/>} />
                <Route path="services" element={<Services/>} />
                <Route path="services/:jobId" element={<Details/>} />
                <Route path="finish" element={<Finish/>} />
                <Route path="error" element={<Error/>} />
                <Route path="generateLink" element={<GenerateLink/>} />
            </Routes>
        </StateProvider>
      </HashRouter>
    );
};

export default App;
