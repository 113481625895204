
export const initialState = {
   
    user:{
        id:null,
        business:null,
        name:null,
        phone:null,
        location:null,
        email:null,
        userPic:null,
    },
    job:{
        id:null,
        Address:null,
        Country:null,
        Description:null,
        CreatedAt:null,
        Location:null,
        Subvertical:null,
        State:null,
        verticalIcon:null,
        Title:null,
        Type:null,
        nJober:null,
        wollers:null,
    }

};
